@import 'https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800&display=swap';
@import 'https://fonts.googleapis.com/css2?family=Chivo:wght@300;400;700&display=swap';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-weight: 500;
  font-family: WhyteInktrap;
  src: local('WhyteInktrap'), url(./fonts/WhyteInktrap-Bold.woff) format('woff');
}

@font-face {
  font-weight: 300;
  font-family: WhyteInktrap;
  src: local('WhyteInktrap'),
    url(./fonts/WhyteInktrap-Regular.woff) format('woff');
}

@font-face {
  font-weight: 700;
  font-family: WhyteInktrap;
  src: local('WhyteInktrap'),
    url(./fonts/WhyteInktrap-Heavy.woff) format('woff');
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
